<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="h4 semiBold"
          >Solicitudes pendientes de Nuevos Usuarios</span
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="w-100 d-flex justify-space-between">
          <div class="d-flex w-100 align-center g3">
            <div>
              <LabelComponent text="Tipo de documento" />
              <v-select
                v-model="selectedTipoDocumento"
                :items="tipo_documentos"
                item-text="descripcion"
                item-value="codigo"
                persistent-hint
                return-object
                outlined
                dense
                append-icon="mdi-chevron-down"
                single-line />
            </div>
            <div>
              <LabelComponent text="Ingrese n° de documento" />

              <TextFieldRut
                :text="text_search.text"
                :prependIcon="text_search.icon"
                placeholder="123646528288"
                name="rut_filtro" />
            </div>

            <v-btn
              color="primary"
              class="ml-6 text-none"
              background-color="white"
              @click="Buscar()"
              :loading="searchLoading"
              rounded
              width="130">
              <Icon name="filter_alt" class="text-white" />
              <span class="ml-2">Filtrar</span>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              to="/personas/register-new-user"
              class="text-none"
              text>
              <Icon name="person_add" size="15px" />
              <span class="ml-3"> Registrar nuevo usuario </span>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <Table
          :items="Personas"
          :headers="headers_two"
          :footer="Personas.length > 3 ? true : false"
          :page="pagination.page"
          no-data-text="No hay documentos para mostrar"
          no-results-text="No se han encontrado documentos"
          loading-text="Cargando documentos..."
          :ActionButton="ActionShield"
          :ActionButton2="ActionReject"
          :ActionButton3="ActionApprove"
          @pagination="setPaginationFromTable($event)" />
      </v-col>
    </v-row>

    <Modal :open="GetEditModal" :userdata="userData" />
    <Reject :open="GetRechazarModal" :userdata="userData" />
    <Approve :open="GetApproveModal" :userdata="userData" />
  </v-container>
</template>
<script>
import Table from "@/components/Tables/SimpleTable";
import Modal from "@/components/Modal/Modal-Authorize";
import Reject from "@/components/Modal/Modal-Reject-user";
import Approve from "@/components/Modal/Modal-Approve-user";
import Icon from "@/components/Icon/Icon.vue";
import TextFieldRut from "@/components/Inputs/TextFieldRut";
import LabelComponent from "@/components/Inputs/Label.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { PaginationMixin } from "@/Mixins/PaginationMixin.js";

export default {
  name: "ListadoAprobacionPersonas",
  mixins: [PaginationMixin],

  components: {
    Table,
    Modal,
    Reject,
    Icon,
    Approve,
    TextFieldRut,
    LabelComponent,
  },
  data() {
    return {
      text_search: {
        placeholder: "Ingresa un rut",
        text: "",
        search: "test",
        icon: "mdi-magnify",
        tipo: "rut",
      },
      searchLoading: false,
      search: "",
      userData: {},
      action: "",
      selectedTipoDocumento: null,
      loadingDocuments: true,
      tipo_documentos: [],
      headers_two: [
        { text: "Tipo documento", width: "10%", value: "tipo_documento" },
        {
          text: "N°documento",
          value: "numero_documento",
          width: "10%",
          align: "left",
        },
        {
          text: "Usuario",
          value: "nombre_largo",
          width: "15%",
          align: "left",
        },
        {
          text: "Canal",
          value: "canal_descripcion",
          width: "10%",
          align: "left",
        },
        {
          text: "Sucursal",
          value: "sucursal_descripcion",
          width: "10%",
          align: "left",
        },

        {
          text: "Fecha de ingreso",
          value: "fecha_creacion",
          width: "10%",
          align: "left",
        },
        // { text: "Estado", value: "estado", width: "10%", align: "left" },
        {
          text: "Aprobar",
          value: "approve",
          width: "7%",
          align: "center",
          sortable: false,
        },
        {
          text: "Rechazar",
          value: "reject",
          width: "7%",
          align: "center",
          sortable: false,
        },
        {
          text: "Cambiar canal o sucursal",
          value: "shield",
          width: "7%",
          align: "center",
          sortable: false,
        },
      ],
      Rechazarmodal: null,
      /* openModalChannel: false, */
    };
  },
  watch: {
    async country(val) {
      if (val) {
        const res = await this.getTiposDocumentos(this.country);
        this.loadingDocuments = false;
        this.tipo_documentos = res;
      }
    },
  },
  methods: {
    ...mapActions("Personas", ["GetAllUsers"]),
    ...mapActions("Internationalization", ["getTiposDocumentos"]),
    ...mapMutations("Personas", ["ApproveModal", "RechazarModal", "EditModal"]),

    ActionShield(item) {
      this.userData = item;
      this.EditModal();
    },
    ActionReject(item) {
      this.userData = item;
      this.RechazarModal();
    },
    ActionApprove(item) {
      this.userData = item;
      this.ApproveModal();
    },
    Buscar() {
      this.search = this.text_search.text.replace(/[^A-Z0-9]/gi, "");
    },

    setup() {
      this.GetAllUsers()
        .then((response) => {
          const pagination = response?.pagination;
          this.setOrResetPagination(pagination);
        })
        .catch((error) => {
          this.$store.commit("setSnackbar", {
            active: true,
            text: error,
            top: true,
            right: true,
            color: "error",
          });
        })
        .finally(() => (this.loading = false));
    },
  },
  async mounted() {
    // this.$store.dispatch("GetAllUsers");
    // this.GetAllUsers();
    this.setup();
  },
  computed: {
    ...mapGetters("Personas", [
      "Personas",
      "GetApproveModal",
      "GetRechazarModal",
      "GetEditModal",
    ]),
    ...mapGetters("Documents", ["Documentos"]),

    // userData() {
    //   return this.$store.getters.User;
    // },

    country() {
      return this.$store.getters.getCountry;
    },
  },
};
</script>

<style scoped>
@import "../../../assets/CSS/ServiceStyle.css";

:deep(.v-input__slot) {
  background-color: white !important;
}
</style>
