<template>
  <div class="text-center">
    <v-dialog v-model="open" width="600px" persistent attach="#main-container">
      <v-card class="py-5">
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <span class="bold h5">Cambiar canal o sucursal </span>
            <v-btn v-on:click.native="close" icon>
              <Icon name="close" size="15px" />
            </v-btn>
          </div>
          <div class="d-flex mt-7 justify-space-between align-center">
            <span class="p">
              Selecciona el nuevo canal y sucursal del usuario
              <span class="bold text-capitalize">
                {{ userdata.nombre_largo }}, Rut
                {{ userdata.usuario | rutFormat }}
              </span>
            </span>
          </div>
          <div class="d-flex mt-4">
            <div class="w-50">
              <LabelComponent text="Canal" />
              <v-select
                v-model="Text_Canal.selected"
                :items="Text_Canal.answers"
                item-text="label"
                item-value="value"
                atta
                outlined
                dense
                no-data-text="No hay información"
                append-icon="mdi-chevron-down"
                @change="setSucursals()"></v-select>
            </div>
            <div class="ml-7 w-50">
              <LabelComponent text="Sucursal" />
              <v-select
                v-model="Text_Sucursal.selected"
                :items="Text_Sucursal.answers"
                item-text="label"
                item-value="value"
                atta
                outlined
                :loading="loadingSucursals"
                dense
                no-data-text="No hay información"
                append-icon="mdi-chevron-down"
                @change="sucursalesApprove"></v-select>
            </div>
          </div>
          <div class="w-100 d-flex justify-center mt-8">
            <v-form ref="authorize">
              <v-btn
                class="text-none"
                color="primary"
                rounded
                outlined
                width="209"
                v-on:click.native="close">
                Cancelar
              </v-btn>
              <v-btn
                v-on:click.native="ChangeSucursal"
                class="ml-4 text-none"
                color="primary"
                rounded
                width="209"
                :loading="loadingstatus"
                :disabled="approveBtn">
                Guardar canal
              </v-btn>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Icon from "@/components/Icon/Icon.vue";
import LabelComponent from "@/components/Inputs/Label.vue";
import { Register } from "../.././Mixins/RegisterMixin";
import { mapMutations, mapGetters, mapActions } from "vuex";

export default {
  mixins: [Register],
  props: ["userdata", "open"],
  name: "ModalEditarAprobacion",
  components: {
    Icon,
    LabelComponent,
  },
  data() {
    return {
      sucursalSelected: "",
      convenioSelected: "",
      Text_Canal: {
        label: "",
        answers: [],
        selected: "",

        id: [],
        rules: [(v) => !!v || "Canal es requerido"],
      },
      Text_Sucursal: {
        label: "",
        answers: [],
        selected: "",
        disabled: true,
        rules: [(v) => !!v || "Sucursal es requerido"],
      },
      loadingCanal: false,
      approveBtn: true,
    };
  },

  methods: {
    ...mapMutations("Personas", ["EditModal"]),
    ...mapActions("Personas", ["ChangeUserStatus"]),

    close() {
      this.approveBtn = true;
      this.Text_Canal.selected = "";
      this.Text_Sucursal.selected = "";
      this.EditModal();
    },

    sucursalesApprove() {
      if (this.$refs.authorize.validate()) {
        this.approveBtn = false;
      }
    },

    ChangeSucursal() {
      this.Personas.map((it) => {
        if (it.id_solicitud === this.userdata.id_solicitud) {
          it.estado = "ACE";
          it.estado_descripcion = "Aceptado";
        }
      });

      if (this.$refs.authorize.validate()) {
        const solicitation = {
          estado: "A",
          motivo: "usuario autorizado",
          canal: this.Text_Canal.selected,
          sucursal: this.Text_Sucursal.selected,
          solicitud: this.userdata.id_solicitud,
          aprobado_por: this.user.usuario,
        };
        this.ChangeUserStatus(solicitation)
          .then((res) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: res.lista_mensaje[0].mensaje,
              top: true,
              right: true,
              color: "success",
            });
          })
          .catch((message) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: message,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => {
            this.close();
            this.approveBtn = true;
            this.$store.getters.Personas.filter((it) => it.estado === "PEN");
          });
      }
    },
  },
  computed: {
    ...mapGetters("Personas", ["Personas"]),

    loadingstatus() {
      return this.$store.getters.loadingstatus;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  watch: {
    open() {
      if (this.open == true) {
        this.textarea = "";
      }
    },
    userdata(userdata) {
      this.Text_Canal.selected = userdata.canal;
      this.setSucursals();
      this.Text_Sucursal.selected = userdata.sucursal;
    },
  },
};
</script>
<style scoped></style>
